import React from 'react'
import { Button } from "antd"
import style from "./style.module.css"


export default function ButtonEle({ style2, content, href }) {
    return (
        <div className={style.main} >
            <a href={href} target='_blank'>
                <Button
                    style={style2}
                    value="123"
                    type="primary"
                    block
                >
                    {content}
                </Button>
            </a>
        </div>
    )
}
