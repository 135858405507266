import React, { useRef } from 'react'
import style from "./style.module.css"
export default function ImageEle({ src, width, height, onload = Function }) {
    const imgRef = useRef(null);

    return (
        <div className={style.main}>
            <img
                ref={imgRef}
                src={src}
                style={{ width, height }}
                alt=""
                onLoad={(e) => onload(true)}
            // onClick={() => onload(imgRef.current)}
            />
        </div>
    )
}
