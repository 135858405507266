const style2 = {
    width: "300px",
    color: "#fff",
    backgroundColor: "#8c52ff",
    height: "60px",
    borderRadius: "2rem",
    fontWeight: "600"
}
const style3 = {
    width: "300px",
    color: "#fff",
    backgroundColor: "#ff66c5",
    height: "60px",
    borderRadius: "2rem",
    fontWeight: "600"
}
const style4 = {
    width: "300px",
    color: "#fff",
    backgroundColor: "#8c52ff",
    height: "60px",
    borderRadius: "2rem",
    fontWeight: "600",
    marginRight: "-32rem",
    // position: "relative",
    // top: "-80px",
}
const style5 = {
    width: "300px",
    color: "#fff",
    backgroundColor: "#ff66c5",
    height: "60px",
    borderRadius: "2rem",
    fontWeight: "600",
    position: "relative",
    left: "-120%",
    top: "0"
    // marginLeft: "-62rem"
}
const style6 = {
    width: "330px",
    color: "#fff",
    backgroundColor: "#8c52ff",
    height: "60px",
    borderRadius: "3rem",
    fontWeight: "600",
    marginRight: "-32rem",
    position: "relative",
    // top: "-130px",
}
//   视图高度判断
// default
const largeScreen = { style2, style3, style4, style5, style6 }
export default largeScreen