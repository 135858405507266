const style2 = {
    width: "8rem",
    color: "#fff",
    backgroundColor: "#8c52ff",
    height: "30px",
    lineHeight: "10px",
    borderRadius: "2rem",
    fontWeight: "600"
}
const style3 = {
    width: "8rem",
    color: "#fff",
    backgroundColor: "#ff66c5",
    lineHeight: "10px",
    height: "30px",
    borderRadius: "2rem",
    fontWeight: "600"
}
const style4 = {
    width: "8rem",
    color: "#fff",
    backgroundColor: "#8c52ff",
    lineHeight: "10px",
    height: "30px",
    borderRadius: "2rem",
    fontWeight: "600",
}
const style5 = {
    lineHeight: "10px",
    width: "8rem",
    color: "#fff",
    backgroundColor: "#ff66c5",
    height: "30px",
    borderRadius: "2rem",
    fontWeight: "600",
    position: "relative",
    top: "0"
}
const style6 = {
    width: "8rem",
    lineHeight: "10px",
    color: "#fff",
    backgroundColor: "#8c52ff",
    height: "30px",
    borderRadius: "3rem",
    fontWeight: "600",

}
//   视图高度判断
// default
const attribute = { style2, style3, style4, style5, style6 }
export default attribute