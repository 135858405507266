import ButtonEle from './pages/ButtonEle';
import { useState, useEffect } from "react"
import ImageEle from './pages/ImageEle';
import img1 from "./imgs/1.png"
import img2 from "./imgs/2.png"
import img3 from "./imgs/3.png"
import img4 from "./imgs/4.png"
import img5 from "./imgs/5.png"
import img6 from "./imgs/6.png"
import img7 from "./imgs/7.png"
import img8 from "./imgs/8.png"
import { Spin } from 'antd';
import './App.css';
import largeScreen from "./property/props"
import attribute from "./property/props1"
import style from "./App.module.css"


function App() {
  // 第一步：声明能够体现视口大小变化的状态
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const updateSize = () => setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  const { style2, style3, style4, style5, style6 } = windowSize.width < 1000 ? attribute : largeScreen;
  // console.log(windowSize.width, "windowSize");

  const exhibition = "https://uxkpl4cba3j.feishu.cn/share/base/form/shrcnHYUtPhbadBQvDq8TwAYFie";
  const joinTheExperience = "https://uxkpl4cba3j.feishu.cn/share/base/form/shrcnTixiQ4yNrsAr5KEIlnCSib"
  const [flag, setFlag] = useState(false)
  const onload = (e) => {
    console.log(e, "e")
    setFlag(e)
  }
  useEffect(() => {
    setFlag(true)

  }, []);
  return (
    <>
      {!flag ? <div className={style.toLoad}>
        <Spin
          size="large"
        />
      </div> : <div className="App">
        <ImageEle src={img1} width="100%" height="100%" />
        <div className={style.btnFater}>
          <ButtonEle style2={style2} href={exhibition} content="预约演示" />
          <ButtonEle style2={style3} href={joinTheExperience} content="申请体验" />
        </div>
        <ImageEle src={img2} width="100%" height="100%" />
        <ImageEle src={img3} width="100%" height="100%" />
        <div >
          <ButtonEle style2={style4} href={exhibition} content="预约演示" />
        </div>
        <ImageEle src={img4} width="100%" height="100%" />
        <ButtonEle style2={style5} href={exhibition} content="加入体检" />
        <ImageEle src={img5} width="100%" height="100%" />
        <ButtonEle style2={style6} href={exhibition} content="预约演示" />
        <ImageEle src={img6} width="100%" height="100%" />
        <ImageEle src={img7} width="100%" height="100%" />
        <ImageEle src={img8} width="100%" height="100%" />
      </div>}




    </>
  );
}

export default App;
